<template>
    <div id="mainpay" class="mainpay">
        <iframe v-show="show" ref="iframe" width="100%" height="100%" marginwidth="0" marginheight="0" frameborder="no" scrolling="no" class="screen"></iframe>
    </div>
</template>
    
<script>
import api from "@/api";

export default {
    props: {
        order: Object
    },
    data() {
        var { orderNo, orderName, receiver, paymentAmount } = this.order;
        
        return {
            show: false,

            orderNo,
            orderName,
            buyerName: receiver.name,
            buyerPhone: receiver.phone,
            buyerEmail: receiver.email,
            paymentAmount,

            response: null,
        }
    },
    async mounted() {
        window.mainpayComplete = (payload) => {
            console.log({ payload });
            this.$emit("complete", payload);
            this.$emit("change", payload);
            this.$emit("input", payload);
            this.close();
        };
        // window.mainpayClose = () => {
        //     this.close();
        // };
    },
    beforeDestroy() {
        window.mainpayComplete = undefined;
    },
    methods: {
        async ready() {
            let { response } = await api.plugins.mainpay.ready({
                orderNo: this.orderNo,
                orderName: this.orderName,
                buyerName: this.buyerName,
                buyerPhone: this.buyerPhone,
                buyerEmail: this.buyerEmail,
                paymentAmount: this.paymentAmount,
            })
            this.response = response;
        },
        close(){
            this.show = false;
        },
        async pay() {
            await this.ready();
            this.show = true;
            this.$nextTick(() => {
                if (navigator.userAgent.indexOf("Android") > -1 || navigator.userAgent.indexOf("iPhone") > -1) {
                    // 모바일일때 iframe 화면 보이기
                    this.$refs.iframe.src = `${this.response.data.nextMobileUrl}`;
                } else {
                    // PC일때 iframe 가리고, 팝업
                    this.$refs.iframe.src = `${this.response.data.nextPcUrl}`;
                }
                // this.$refs.iframe.src = `${this.response.data.nextPcUrl}`;
            });
        },
    }
};
</script>

<style scoped>
#mainpay .screen {
    position: fixed;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    z-index: 99999;
    background: rgba(0,0,0,0.5);
}
</style>
