var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.order ? _c(_vm.pg, {
    ref: "pg",
    tag: "component",
    attrs: {
      "order": _vm.order
    },
    on: {
      "complete": _vm.complete
    }
  }) : _vm._e();

}
var staticRenderFns = []

export { render, staticRenderFns }