<template>
    <div id="paytus" class="paytus">
        <div v-show="show" ref="screen" class="screen" style="display: none;">
            <iframe ref="iframe" width="100%" height="700" frameborder="0" scrolling="true"></iframe>
        </div>
        <div v-show="show" ref="progress" class="progress" style="display: none;">
            <div class="spinner"></div>
        </div>
    </div>
</template>
<script>
export default {
    props: {
        order: Object,
    },
    data() {
        var { orderNo, orderName, receiver, paymentAmount} = this.order;

        return {
            show: false,

            orderNo,
            orderName,
            paymentAmount,
            buyerName: receiver.name,
            buyerPhone: receiver.phone,
            buyerEmail: receiver.email,

        }
    },
    mounted() {
        window.paytusComplete = (payload) => {
            this.$emit("complete", payload);
            this.$emit("change", payload);
            this.$emit("input", payload);
            this.close();
        };
        window.paytusClose = () => {
            this.close();
        };
    },
    beforeDestroy() {
        window.paytusComplete = undefined;
    },
    methods: {
        close(){
            this.show = false;
        },
        async pay() {
            this.show = true;
            this.$nextTick(() => {

                if (navigator.userAgent.indexOf("Android") > -1 || navigator.userAgent.indexOf("iPhone") > -1) {
                    // 모바일일때 iframe 화면 보이기
                    this.$refs.screen.style.display = "";
                    this.$refs.progress.style.display = "";
                    this.ui = "self";
                } else {
                    // PC일때 iframe 가리고, 팝업
                    // this.$refs.screen.style.display = "none";
                    // this.$refs.progress.style.display = "none";
                    // this.ui = "popup";
                }
                this.$refs.iframe.src = `/plugins/paytus/request?${this.queryString}`;
            });
        },
    },
    computed: {
        queryString(){
            return Object.keys(this.$data).reduce((qs, key) => `${qs}${qs ? '&' : ''}${key}=${encodeURI(this.$data[key])}`, "");
        },
    }
};
</script>

<style scoped>
#paytus .screen {
    position: fixed;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    z-index: 99999;
    background: rgba(0,0,0,0.5);
}
</style>