var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('form', {
    ref: "form",
    attrs: {
      "name": "payment",
      "method": "post",
      "action": _vm.mobileGateway
    }
  }, [_c('input', {
    attrs: {
      "type": "hidden",
      "name": "ServerInfo"
    },
    domProps: {
      "value": _vm.ServerInfo
    }
  }), _c('input', {
    attrs: {
      "type": "hidden",
      "name": "BgColor",
      "value": "00"
    }
  }), _c('input', {
    attrs: {
      "type": "hidden",
      "name": "TargetURL"
    },
    domProps: {
      "value": _vm.returnUrl
    }
  }), _c('input', {
    attrs: {
      "type": "hidden",
      "name": "BackURL"
    },
    domProps: {
      "value": _vm.returnUrl
    }
  }), _c('input', {
    attrs: {
      "type": "hidden",
      "name": "Email"
    },
    domProps: {
      "value": _vm.buyerEmail
    }
  }), _c('input', {
    attrs: {
      "type": "hidden",
      "name": "IsDstAddr"
    },
    domProps: {
      "value": _vm.buyerPhone
    }
  }), _c('input', {
    attrs: {
      "type": "hidden",
      "name": "IsCharSet",
      "value": "UTF8"
    }
  }), _c('input', {
    attrs: {
      "type": "hidden",
      "name": "CPName"
    },
    domProps: {
      "value": _vm.cpName
    }
  }), _c('input', {
    attrs: {
      "type": "hidden",
      "name": "ItemName"
    },
    domProps: {
      "value": _vm.orderName
    }
  }), _c('input', {
    attrs: {
      "type": "hidden",
      "name": "ItemAmt"
    },
    domProps: {
      "value": _vm.paymentAmount
    }
  }), _c('input', {
    attrs: {
      "type": "hidden",
      "name": "orderNo"
    },
    domProps: {
      "value": _vm.orderNo
    }
  }), _c('input', {
    attrs: {
      "type": "hidden",
      "name": "IsPreOtbill",
      "value": "N"
    }
  }), _c('input', {
    attrs: {
      "type": "hidden",
      "name": "IsSubscript",
      "value": "N"
    }
  }), _c('input', {
    attrs: {
      "type": "hidden",
      "name": "IsExceptCarrier"
    },
    domProps: {
      "value": _vm.isExceptCarrier
    }
  }), _c('input', {
    attrs: {
      "type": "hidden",
      "name": "IsCarrier"
    },
    domProps: {
      "value": _vm.isCarrier
    }
  }), _c('input', {
    attrs: {
      "type": "hidden",
      "name": "IsIden"
    },
    domProps: {
      "value": _vm.authNumber
    }
  })]);

}
var staticRenderFns = []

export { render, staticRenderFns }