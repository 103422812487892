<template>
    <div id="settlebank" class="settlebank">
        <div v-show="show" ref="screen" class="screen" style="display: none;">
            <iframe ref="iframe" width="100%" height="700" frameborder="0" scrolling="true"></iframe>
        </div>
    
        <div v-show="show" ref="progress" class="progress" style="display: none;">
            <div class="spinner"></div>
        </div>
        <slot name="activator" :on="{ click: open }" />
    </div>
</template>

<script>
export default {
    props: {
        order: Object
    },
    data() {
        var { orderNo, orderName, buyer, paymentAmount, paymentMethod, telecom } = this.order;

        return {
            show: false,

            orderNo, //           상품 주문번호
            orderName, //         결제상품명
            paymentAmount, //     상품 금액
            paymentMethod,

            buyerUsername: buyer.username, //     구매자 아이디
            buyerName: buyer.name, //         구매자명
            buyerPhone: buyer.phone, //          구매자 전화번호
            buyerEmail: buyer.email, //        구매자 이메일

            receiverName: null, //      수취인 이름
            receiverPhone: null, //     수취인 연락처
            receiverPostcode: null, //  배송지 우편번호
            receiverAddress1: null, //  배송지 주소1
            receiverAddress2: null, //  배송지 주소2

            ui: null,
            telecom,
        }
    },
    mounted() {
        window.settlebankComplete = (payload) => {
            this.$emit("complete", payload);
            this.$emit("change", payload);
            this.$emit("input", payload);
            this.show = false;
        };
    },
    beforeDestroy() {
        window.settlebankComplete = undefined;
    },
    methods: {
        async pay() {
            this.show = true;
            this.$nextTick(() => {
                if (navigator.userAgent.indexOf("Android") > -1 || navigator.userAgent.indexOf("iPhone") > -1) {
                    // 모바일일때 iframe 화면 보이기
                    this.$refs.screen.style.display = "";
                    this.$refs.progress.style.display = "";
                    this.ui = "self";
                } else {
                    // PC일때 iframe 가리고, 팝업
                    this.$refs.screen.style.display = "none";
                    this.$refs.progress.style.display = "none";
                    this.ui = "popup";
                }
                this.$refs.iframe.src = `/plugins/hecto-financial/settlebank/request?` + Object.keys(this.$data).reduce((query, key) => query + `${key}=${encodeURIComponent(this.$data[key])}&`, ``);
            });
        },
    },
};
</script>

<style scoped>
#settlebank .screen {
    position: fixed;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    z-index: 99999;
    overflow-y: scroll;
    background: rgba(0,0,0,0.5);
}
#settlebank iframe {
    position: fixed;
    width: 100%;
    height: 100%;
}
.settlebank .progress{
    position: fixed;
    width: 100%;
    height: 100%;
    left:0;
    top: 0;
    background: rgba(0,0,0,0.54);
}
.settlebank .spinner {
    position: fixed;
    margin: auto;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    height: 28px;
    width: 28px;
    animation: rotate 0.8s infinite linear;
    border: 8px solid #fff;
    border-right-color: transparent;
    border-radius: 50%;
}
@keyframes rotate {
    0%    { transform: rotate(0deg); }
    100%  { transform: rotate(360deg); }
}
</style>