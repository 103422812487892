<template>
    <div v-show="show" class="popup-background" @click="close()" name="KSPayWeb" method="get" action="api/plugins/onflatpay" >
        <div class="popup" @click.stop>
            <div class="popup__head text-center">
                <h2>온플랫 카드 수기 결제</h2>
            </div>
            <div class="popup__body">
                <table border=0 width=100%>
                    <tr style="display: none;">
                        <td>
                            <hr noshade size=1>
                            <b>온플랫 결제</b>
                            <hr noshade size=1>
                        </td>
                    </tr>
                </table>
                <div class="pa-3 w-100 primary text-center">
                    <p class="white--text mb-0">정보를 확인 하신 후 지불버튼을 눌러주십시오</p>
                </div>
                <br>
                <table class="table--pay-view">
                <tr style="display: none;">
                <!----------------------------------------------- < Part 2. 고객에게 보여지지 않는 항목 > ------------------------------------>
                <!--이부분은 결제를 위해 상점에서 기본정보를 세팅해야 하는 부분입니다.														-->
                <!--단 고객에게는 보여지면 안되는 항목이니 테스트 후 필히 hidden으로 변경해주시길 바랍니다.									-->
                <td colspan=2>고객에게 보여지지 않아야 하는 설정값 항목</td>
                </tr>
                <tr style="display: none;">
                    <td>결제수단</td>
                    <td>
                        <select name=sndPaymethod>
                            <option value="1000000000" selected>신용카드</option>
                                <option value="0100000000">가상계좌</option>
                                <option value="0010000000">계좌이체</option>
                                <option value="0000010000" >휴대폰결제</option>
                        </select>
                    </td>
                </tr>
                <tr style="display: none;">
                    <td>상점아이디</td>
                    <td><input type=text name="sndStoreid" size="10" maxlength="10" :value="sndStoreid"></td></tr>
                <tr style="display: none;">
                <!-- 화폐단위 원화로 설정 : 410 또는 WON -->
                    <td>화폐단위</td>
                    <td><input type=text name=sndCurrencytype  size=30 maxlength=3 value="WON"></td>
                </tr>
                <tr>
                <!--상품명은 30Byte(한글 15자) 입니다. 특수문자 ' " - ` 는 사용하실수 없습니다. 따옴표,쌍따옴표,빼기,백쿼테이션 -->
                    <td>주문번호</td>
                    <td><input type=text name=sndOrdernumber  size=30 maxlength=30 :value="orderNo" readonly class="input"></td>
                </tr>
                <tr style="display: none;">
                <!--주민등록번호는 필수값이 아닙니다.-->
                    <td>주민번호 : </td>
                    <td><input type=text name=sndAllregid  size=30 maxlength=13 value="">
                </td>
                </tr>
                <tr style="display: none;">
                    <td colspan=2><hr></td></tr>
                <tr style="display: none;">
                    <td colspan=2>신용카드 기본항목</td>
                </tr>
                <tr style="display: none;">
                <!--상점에서 적용할 할부개월수를 세팅합니다. 여기서 세팅하신 값은 KSPAY결재팝업창에서 고객이 스크롤선택하게 됩니다 -->
                <!--아래의 예의경우 고객은 0~12개월의 할부거래를 선택할수있게 됩니다. -->
                    <td>할부개월수</td>
                    <td><input type=text name=sndInstallmenttype size=30 maxlength=30 value="ALL(0:2:3:4:5:6:7:8:9:10:11:12:13:14:15:16:17:18:19:20:21:22:23:24:25:26:27:28:29:30:31:32:33:34:35:36)"></td>
                </tr>
                <tr style="display: none;">
                <!--무이자 구분값은 중요합니다. 무이자 선택하게 되면 상점쪽에서 이자를 내셔야합니다.-->
                <!--무이자 할부를 적용하지 않는 업체는 value='NONE" 로 넘겨주셔야 합니다. -->
                <!--예 : 모두 무이자 적용할 때는 value="ALL" / 무이자 미적용할 때는 value="NONE" -->
                <!--예 : 3,4,5,6개월 무이자 적용할 때는 value="3:4:5:6" -->
                    <td>무이자구분</td>
                    <td><input type=text name=sndInteresttype  size=30 maxlength=30 value="NONE"></td>
                </tr>
                <tr style="display: none;">
                    <td>신용카드표시구분</td>
                    <td><input type=text name=sndShowcard  size=30 maxlength=30 value="C"></td>
                </tr>
                <tr style="display: none;">
                <!----------------------------------------------- <Part 3. 고객에게 보여주는 항목 > ----------------------------------------------->
                    <td colspan=2>고객에게 보여주는 항목</td>
                </tr>
                <tr>
                <!--상품명은 30Byte(한글 15자)입니다. 특수문자 ' " - ` 는 사용하실수 없습니다. 따옴표,쌍따옴표,빼기,백쿼테이션 -->
                    <td>상품명</td>
                    <td><input type=text name=sndGoodname  size=30 maxlength=30 :value="orderName" readonly class="input"></td>
                </tr>
                <tr>
                    <td>가격</td>
                    <td><input type=text name=sndAmount  size=30 maxlength=9 :value="paymentAmount" readonly class="input"></td>
                </tr>
                <tr>
                    <td>성명</td>
                    <td><input type=text name=sndOrdername  size=30 maxlength=20 :value="buyerName" :readonly="logon" class="input"></td>
                </tr>
                <!--KSPAY에서 결제정보를 메일로 보내줍니다.(신용카드거래에만 해당)-->
                <!-- <tr>
                    <td>전자우편 : </td>
                    <td>
                    <input type=text name=sndEmail  size=30 maxlength=50 :value=email placeholder="영수증을 email로 받으려면 작성하세요.">
                    </td>
                </tr>	 -->
                <!--카드사에 SMS 서비스를 등록하신 고객에 한해서 SMS 문자메세지를 전송해 드립니다.-->
                <!--전화번호 value 값에 숫자만 넣게 해주시길 바랍니다. : '-' 가 들어가면 안됩니다.-->
                <tr>
                    <td>전화번호</td>
                    <td>
                    <input type=text name=sndMobile  size=30 maxlength=12 :value=buyerPhone readonly class="input" placeholder="숫자만 기입해주세요.">
                    </td>
                </tr>
                <tr>
                    <td>카드번호</td>
                    <td>
                        <input v-model="cardNum" name="cardNum" value="" type="number" class="input">
                    </td>
                </tr>
                <tr>
                    <td>유효기간</td>
                    <td>
                        <div class="input-group">
                            <input v-model="expiryDate" type="number" name="cardExpire" value="" placeholder="MMYY" class="input">
                        </div>
                    </td>
                </tr>
                <tr>
                    <td>할부개월</td>
                    <td>
                        <!-- <input v-model="sndInstallmenttype" type="hidden" name="cardQuota" value="00" placeholder="선택"> 필수: 할부개월 (ex. 일시불: 00, 2개월: 02, 3개월 03 ...) -->
                        <select v-model="sndInstallmenttype" name="cardQuota" required class="input">
                            <option value="" disabled selected>선택</option>
                            <option value="00">일시불</option>
                            <option v-for="month in 12" :value="String(month).padStart(2, 0)">{{ month }}개월</option>
                        </select>
                    </td>
                </tr>
                <tr>
                    <td>카드비밀번호</td>
                    <td>
                        <input v-model="password2" name="cardPwd" maxlength="2" type="password" value="" placeholder="앞 2자리" class="input"> <!-- 옵션: 카드비밀번호 (앞 2자리, 가맹점 옵션에 따른 입력 사항) -->
                    </td>
                </tr>
                <tr>
                    <td>인증번호</td>
                    <td>
                        <input name="buyerAuthNum" :value=authNo readonly placeholder="생년월일 6자리(YYMMDD)" class="input"> <!-- 옵션: 생년월일 6자리(개인카드) or 사업자번호 10자리(법인카드) (가맹점 옵션에 따른 입력 사항) -->
                    </td>
                </tr>
                </table>
                <table border=0 width=100% style="display: none">
                    <tr>
                    <td><hr noshade size=1></td>
                    </tr>
                    <input type=hidden name=sndCharSet value="utf-8">									<!-- 가맹점 문자열 체어셋 -->
                    <input type=hidden name=sndReply value="">
                    <input type=hidden name=sndEscrow value="0">                           <!--에스크로적용여부-- 0: 적용안함, 1: 적용함 -->
                    <input type=hidden name=sndVirExpDt value="">                            <!-- 마감일시 -->
                    <input type=hidden name=sndVirExpTm value="">                            <!-- 마감시간 -->
                    <input type=hidden name=sndStoreName value="온플랫(주)">                  <!--회사명을 한글로 넣어주세요(최대20byte)-->
                    <input type=hidden name=sndStoreNameEng value="ONPALT">                       <!--회사명을 영어로 넣어주세요(최대20byte)-->
                    <input type=hidden name=sndStoreDomain value="https://dev-api.on-plat.com"> <!-- 회사 도메인을 http://를 포함해서 넣어주세요-->
                    <input type=hidden name=sndGoodType	value="1">								           <!--실물(1) / 디지털(2) -->
                    <input type=hidden name=sndUseBonusPoint value="">   							           <!-- 포인트거래시 60 -->                                                                                                                                                           
                    <input type=hidden name=sndRtApp value="">									           <!-- 하이브리드APP 형태로 개발시 사용하는 변수 -->
                    <input type=hidden name=sndStoreCeoName value="">                            <!--  카카오페이용 상점대표자명 --> 
                    <input type=hidden name=sndStorePhoneNo value="">                            <!--  카카오페이 연락처 --> 
                    <input type=hidden name=sndStoreAddress value="">                            <!--  카카오페이 주소 --> 
                </table>
            </div>
            <div class="popup__foot d-flex justify-center">
                <v-btn @click="close()" color="#d8d8d8" elevation="0">취소</v-btn>
                <v-btn @click="submit()" color="primary" :disabled="loading" elevation="0">결제</v-btn>
                <!-- <button class="button-grey" @click="close()">취소</button>
                <button class="button-primary" @click="pay">결제</button> -->
            </div>
        </div>
    </div>
</template>

<script>

import api from "@/api";
import axios from "axios";

export default {
    props: {
        order: Object,
    },
    data() {
        var { orderNo, orderName, receiver, paymentAmount } = this.order;

        return {
            show: false,

            pgInfoId: null, //pg정보조회 시 아이디,
            sndStoreid:null,

            orderNo,
            orderName, //주문명
            buyerName: receiver.name,
            buyerPhone: receiver.phone,
            paymentAmount,
            cardNum: null,
            sndInstallmenttype: 0,
            expiryDate: null,
            password2: null,
            authNo: receiver.birthday?.substr?.(2, 6) || '',

            loading: false,
        }
    },
    mounted() {
    },

    methods: {
        pay: function(){
            this.show = true;
        },
        close: function(){
            this.show = false;
        },
        validate(){
            try{
                if(!this.cardNum) throw new Error("카드번호를 입력해 주세요");
                if(this.cardNum.length <15 || this.cardNum.length > 16) throw new Error("카드번호를 다시 입력해 주세요");
                if(!this.expiryDate || this.expiryDate.length !== 4) throw new Error("유효기간을 입력해 주세요");
                if(!this.sndInstallmenttype) throw new Error("할부개월을 선택해 주세요");
                if(!this.password2 || this.password2.length !==2) throw new Error("카드비빌번호 앞 2자리를 입력해 주세요");
                return true
            }
            catch(error){
                alert(error.message);
                return false;
            }
        },
        async submit() {
            if(this.validate()) {
                let { siteInfo } = await api.site.info.get();  
                this.sndStoreid = Number(siteInfo.onflatMid);
    
                this.expiryDate = this.expiryDate.slice(2,4) + this.expiryDate.slice(0,2)
                this.loading = true;
                
                await axios({
                        method: "get", //get pgInfoId
                        url: `https://pro-api.pay-onplat.com/onplat/out/pgInfo?=storeId=${this.sndStoreid}`,
                        headers: {
                            "Content-Type": `application/json`
                        },
                    })
                    .then(data => this.pgInfoId = data.data[0].id)

                    let response = await api.plugins.onplatpay.post({
                        pgInfoId: this.pgInfoId, //pg정보조회 시 아이디
                        storeId: this.sndStoreid, // onplat에서 준 가맹점 아이디
                        productName: this.orderName, // 상품명
                        customerName: this.buyerName, // 구매자명
                        customerPhone: this.buyerPhone, // 구매자연락처
                        totalAmount: this.paymentAmount, // 결제금액
                        cardNum: this.cardNum, // 카드번호
                        cardInst: this.sndInstallmenttype, // 할부개월수
                        expiryDate: this.expiryDate, // 유효기간
                        password2: this.password2, // 비밀번호 앞2자리
                        userInfo: this.authNo, //생년월일(YYMMDD)
                        returnVal: ``, //그대로 되돌려받을 값 상점처리용 (선택사항)
                        orderNo: this.orderNo
                    })
        
                    this.$emit("complete", response);
        
                    if(response.chargeState === '승인') this.$router.push({
                            path: '/gshop/result', query: { orderNo: response.orderNo, chargeState: response.chargeState }
                    });
            }
        }
    },

    computed: {
        accessToken(){
            return this.$store.state.accessToken;
        },

        logon(){
            return !!this.accessToken;
        },

    }
}
</script>

<style scoped>
    .table--pay-view{
        width: 100%;
        border-top: 1px solid #000;
        table-layout: fixed;
    }

    .table--pay-view tr>td:first-child{
        width: 130px;
        background-color: #f7f7f7;
        text-align: center;
    }

    .table--pay-view tr>td{
        height: 48px;
        vertical-align: center;
        padding: 10px;
        border-bottom: 1px solid rgba(0,0,0,.12);
    }

    .table--pay-view .input{
        width: 100%;
        height: 42px;
        border: 1px solid #b9b9b9;
        padding: 0 12px;
        border-radius: 4px;
    }

    .table--pay-view select.input{
        background-image: url(/images/icon/select-arrow.png);
        background-position: right 12px center;
        background-repeat: no-repeat;
        background-size: 12px auto;
        position: relative;
        padding: 0 36px 0 12px;
        cursor: pointer;
    }

    select option[value=""][disabled] {
        display: none;
    }

    @media (max-width: 768px){
        .table--pay-view tr,
        .table--pay-view td{
            display: block;
            width: 100%;
        }
        .table--pay-view tr>td:first-child{
            width: 100%;
            height: 42px;
        }
        .table--pay-view tr>td:last-child{
            height: 62px;
        }
    }
    .popup-background{
    position: fixed;
    top:0;
    left:0;
    z-index: 10001;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100vh;
    padding: 20px 0;
    background-color: rgba(0,0,0,.15);
    }
    .popup{
        width: 100%;
        max-width: 600px;
        max-height: 100%;
        background-color: #fff;
        border-radius: 12px;
        box-shadow: 0 0 10px rgba(58,58,58,.2);
        overflow: auto;
    }
    .popup__head{
        text-align: left;
        padding:20px 40px;
        border-bottom:1px solid #ccc;
    }
    .popup__head h2{
        font-size: 26px;
        font-weight: 500;
        color:#252525;
        margin-bottom: 0;
    }
    .popup__body{
        padding:36px 40px;
    }
    .popup__foot{
        padding: 40px;
    }
    .popup__body + .popup__foot{
        padding-top: 0;
    }
    .popup__foot a,
    .popup__foot button{
        width: 120px;
        height: 45px;
        font-size: 18px;
        font-weight: 500;
        color:#fff;
        border-radius: 6px;
        background-color: #b0b0b0;
        margin:0 5px;
    }
    @media (max-width: 768px){
        .popup__head,
        .popup__body{
            padding: 20px;
        }
        .popup__body{
            width: 100%;
            overflow-x: hidden;
        }
    }
</style>


