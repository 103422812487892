<template>
    <form ref="form" name="payment" method="post" :action="mobileGateway">
        <input type="hidden" name="ServerInfo" :value="ServerInfo" />
        <!-- 
            /******************************************************
            * BgColor      : 결제 페이지 Background Color 설정
            * TargetURL    : 최종 결제 요청 할 CP의 CPCGI FULL URL
            * BackURL      : 에러 발생 및 취소 시 이동 할 페이지의 FULL URL
            * IsUseCI      : CP의 CI 사용 여부( Y or N )
            * CIURL        : CP의 CI FULL URL
            ******************************************************/
         -->
        <input type="hidden" name="BgColor" value="00" />
        <input type="hidden" name="TargetURL" :value="returnUrl" />
        <input type="hidden" name="BackURL" :value="returnUrl" />

        <!--
            /***[ 선택 사항 ]**************************************/
            /******************************************************
            * Email	: 사용자 E-mail 주소 - 결제 화면에 표기
            * IsCharSet	: CP의 Webserver Character set
            ** CPCGI에 POST DATA로 전달 됩니다.
            **
            ******************************************************/
        -->
        <input type="hidden" name="Email" :value="buyerEmail" />
        <input type="hidden" name="IsDstAddr" :value="buyerPhone" />
        <input type="hidden" name="IsCharSet" value="UTF8" />

        <input type="hidden" name="CPName" :value="cpName" />
        <input type="hidden" name="ItemName" :value="orderName" />
        <input type="hidden" name="ItemAmt" :value="paymentAmount" />
        <input type="hidden" name="orderNo" :value="orderNo" />

        <input type="hidden" name="IsPreOtbill" value="N" />
        <input type="hidden" name="IsSubscript" value="N" />

        <input type="hidden" name="IsExceptCarrier" :value="isExceptCarrier" />
        <input type="hidden" name="IsCarrier" :value="isCarrier" />
        <input type="hidden" name="IsIden" :value="authNumber" />
    </form>
</template>

<script>
import api from "@/api";
export default {
    props: {
        order: Object
    },
    data() {

        let { orderNo, orderName, buyer, paymentAmount, telecom = "", authNumber = "" } = this.$props.order;

        return {
            // orderCode: "",
            orderNo,
            orderName,
            buyerPhone: buyer.phone,
            buyerEmail: buyer.email,
            // 결제정보
            paymentAmount,
            quantity: 1,
            // buyerName: "",
            // buyerUsername: "",
            telecom,
            authNumber,

            // 결제시작
            mobileGateway: "",
            ServerInfo: "",
            cpName: "",
        };
    },
    mounted() {
        window.danalPPayComplete = (payload) => {
            this.$emit("complete", payload);
            this.$emit("change", payload);
            this.$emit("input", payload);
            this.show = false;
        };
    },
    methods: {
        async pay() {
            console.log({ ...this.$data });
            let { mobileGateway, cpName } = await api.plugins.danal.pPay.config.get();
            this.mobileGateway = mobileGateway;
            this.cpName = cpName;

            let { ServerInfo } = await api.plugins.danal.pPay.generate(this.$data);
            this.ServerInfo = ServerInfo;

            this.$refs.form.target = "payment";
            this.$nextTick((_) => {
                var modalOption = "width=520,height=694,toolbar=no,location=no,status=no,menubar=no,scrollbars=no,resizable=no,left=150,top=150";
                var modal = window.open("", "payment", modalOption);

                if (modal == null) {
                    //팝업 차단여부 확인
                    alert("팝업이 차단되어 있습니다.\n팝업차단을 해제하신 뒤 다시 시도하여 주십시오.");
                }

                //인코딩 euc-kr 처리
                if (/edge/.test(window.navigator.userAgent.toLowerCase()) || /MSIE/.test(window.navigator.userAgent) || /Trident.*rv[ :]*11\./.test(window.navigator.userAgent)) {
                    var befCharset = document.charset;
                    document.charset = "euc-kr";
                    // window.document.charset = "utf-8";
                    // document.charset = befCharset;
                } else {
                    this.$refs.form.acceptCharset = "euc-kr";
                    // this.$refs.form.acceptCharset = "utf-8";
                }

                this.$refs.form.submit();

                if (befCharset) document.charset = befCharset;
            });
        },
    },
    computed: {
        returnUrl() {
            return window.location.origin + "/api/plugins/danal/p-pay/response";
        },
        isCarrier(){
            switch(this.telecom){
                case "SKT": return "SKT";
                case "KTF": return "KT";
                case "LGT": return "LGT";
                case "CJH": return "CJH";
                case "KCT": return "KCT";
                case "SKL": return "SKL";
            }
        },
        isExceptCarrier(){
            let excepts = ["SKT", "KT", "LGT", "CJH", "KCT", "SKL"];
            return excepts.filter(except => except != this.isCarrier).join(";");
        }
    },
};
</script>
