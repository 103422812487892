<template>
<div id="korpay" class="korpay">
    <iframe v-show="show" ref="iframe" width="100%" height="100%" marginwidth="0" marginheight="0" frameborder="no" scrolling="no" class="screen"></iframe>
</div>
</template>

<script>
export default {
    props: {
        order: Object,
    },
    data() {
        var { orderNo, orderName, receiver, paymentAmount} = this.order;

        return {
            show: false,

            orderNo,
            orderName,
            paymentAmount,
            buyerName: receiver.name,
        }
    },
    mounted() {
        window.korpayComplete = (payload) => {
            this.$emit("complete", payload);
            this.$emit("change", payload);
            this.$emit("input", payload);
            this.close();
        };
        window.korpayClose = () => {
            this.close();
        };
    },
    beforeDestroy() {
        window.korpayComplete = undefined;
    },
    methods: {
        close(){
            this.show = false;
        },
        async pay() {
            this.show = true;
            this.$nextTick(() => {
                // if (navigator.userAgent.indexOf("Android") > -1 || navigator.userAgent.indexOf("iPhone") > -1) {
                //     // 모바일일때 iframe 화면 보이기
                // } else {
                //     // PC일때 iframe 가리고, 팝업
                //     this.$refs.screen.style.display = "none";
                //     this.$refs.progress.style.display = "none";
                // }
                this.$refs.iframe.src = `/plugins/korpay/request?${this.queryString}`;
            });
        },
    },
    computed: {
        queryString(){
            return Object.keys(this.$data).reduce((qs, key) => `${qs}${qs ? '&' : ''}${key}=${encodeURI(this.$data[key])}`, "");
        }
    }
};
</script>

<style scoped>
#korpay .screen {
    position: fixed;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    z-index: 99999;
    background: rgba(0,0,0,0.5);
}
</style>
