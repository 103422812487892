<template>
    <form ref="form" name="payment" method="post" :action="STARTURL">
        <input type="hidden" name="STARTPARAMS" :value="STARTPARAMS" />
    </form>
</template>

<script>
import api from "@/api";
export default {
    props: {
        order: Object
    },
    data() {
        let { 
            orderNo,
            orderName,
            paymentAmount,
            buyer
        } = this.$props.order;

        return {
            // 결제정보
            orderNo,
            orderName,
            paymentAmount,
            buyerName: buyer.name,
            buyerUsername: buyer.username,
            buyerEmail: buyer.email,
            useragent: this.$vuetify.breakpoint.mobile ? "WM" : "WP",

            // 결제시작
            STARTURL: "",
            STARTPARAMS: "",
        };
    },
    computed: {
        returnUrl() {
            return window.location.origin + "/api/plugins/danal/t-pay/response";
        },
        paramsForGenerate() {
            let { orderNo, orderName, paymentAmount, buyerName, buyerUsername, buyerEmail, useragent, returnUrl } = this;
            return { orderNo, orderName, paymentAmount, buyerName, buyerUsername, buyerEmail, useragent, returnUrl };
        },
    },
    mounted() {
        window.danalTPayComplete = (payload) => {
            this.$emit("complete", payload);
            this.$emit("change", payload);
            this.$emit("input", payload);
            this.show = false;
        };
    },
    methods: {
        async pay() {
            console.log({ ...this.paramsForGenerate });
            let { STARTURL, STARTPARAMS } = await api.plugins.danal.tPay.generate(this.paramsForGenerate);

            this.STARTURL = STARTURL;
            this.STARTPARAMS = STARTPARAMS;

            this.$refs.form.target = "payment";
            this.$nextTick((_) => {
                var modalOption = "width=800,height=491,toolbar=no,location=no,status=no,menubar=no,scrollbars=no,resizable=no,left=150,top=150";
                var modal = window.open("", "payment", modalOption);

                if (modal == null) {
                    //팝업 차단여부 확인
                    alert("팝업이 차단되어 있습니다.\n팝업차단을 해제하신 뒤 다시 시도하여 주십시오.");
                }

                //인코딩 euc-kr 처리
                if (/edge/.test(window.navigator.userAgent.toLowerCase()) || /MSIE/.test(window.navigator.userAgent) || /Trident.*rv[ :]*11\./.test(window.navigator.userAgent)) {
                    var befCharset = document.charset;
                    document.charset = "euc-kr";
                    // window.document.charset = "utf-8";
                    // document.charset = befCharset;
                } else {
                    this.$refs.form.acceptCharset = "euc-kr";
                    // this.$refs.form.acceptCharset = "utf-8";
                }

                this.$refs.form.submit();

                if (befCharset) document.charset = befCharset;
            });
        },
    },
};
</script>
