<template>
    <div v-if="show" id="allatpay" class="allatpay">
        <iframe ref="iframe" :src="'/plugins/allatpayments/allatpay/request?' + queryString" width="100%" height="100%" marginwidth="0" marginheight="0" frameborder="no" scrolling="no" class="screen" allowtransparency="true" style="display: none"></iframe>
    </div>
</template>
    
<script>
export default {
    props: {
        order: Object,
    },
    data() {
        var { orderNo, orderName, receiver, paymentAmount } = this.order;
        
        return {
            show: false,

            collection: "",
            orderNo,
            orderName,
            paymentMethod: "",
            paymentAmount,
            buyerName: receiver.name,
            buyerPhone: receiver.phone,
            buyerEmail: receiver.email,
        }
    },
    methods: {
        close(){
            this.show = false;
            window.allatpayComplete = undefined;
            window.allatpayClose = undefined;
        },
        async pay() {
            this.show = true;
            this.$nextTick(() => {
                window.allatpayComplete = (payload) => {
                    this.$emit("complete", payload);
                    this.$emit("change", payload);
                    this.$emit("input", payload);
                    this.close();
                };
                window.allatpayClose = () => {
                    this.close();
                };

                this.$refs.iframe.src = `/plugins/kg/allatpay/request?${this.queryString}`;
                this.$refs.iframe.style.display = this.display;
            });
        },
    },
    computed: {
        queryString(){
            return Object.keys(this.$data).reduce((qs, key) => `${qs}${qs ? '&' : ''}${key}=${encodeURI(this.$data[key])}`, "");
        },
        display(){
            if (navigator.userAgent.indexOf("Android") > -1 || navigator.userAgent.indexOf("iPhone") > -1) {
                // 모바일일때 iframe 화면 보이기
                return "";
            } else {
                // PC일때 iframe 가리고, 팝업
                return "none";
            }
        }
    }
};
</script>

<style scoped>
#allatpay .screen {
    position: fixed;
    width: 100%;
    height: 100vh;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    z-index: 99999;
    /* background: rgba(0, 0, 0, 0.5); */
    background: rgba(255, 255, 255, 1.0);
}
</style>