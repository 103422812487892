<template>
<div id="wizzpay" class="wizzpay">
    <div v-show="show" ref="screen" class="screen" style="display: none;">
        <iframe ref="iframe" width="100%" height="700" frameborder="0" scrolling="true"></iframe>
    </div>

    <div v-show="show" ref="progress" class="progress" style="display: none;">
        <div class="spinner"></div>
    </div>
    <slot name="activator" :on="{ click: open }" />
</div>
</template>

<script>
export default {
    props: {
        order: Object,
    },
    data() {
        var { orderNo, orderName, receiver, paymentAmount } = this.order;

        return {
            show: false,

            orderNo,
            orderName,
            paymentAmount,
            buyerName: receiver.name,
        }
    },
    mounted() {
        window.wizzpayComplete = (payload) => {
            this.$emit("complete", payload);
            this.$emit("change", payload);
            this.$emit("input", payload);
            this.show = false;
        };
    },
    beforeDestroy() {
        window.wizzpayComplete = undefined;
    },
    methods: {
        async pay() {
            this.show = true;
            this.$nextTick(() => {
                if (navigator.userAgent.indexOf("Android") > -1 || navigator.userAgent.indexOf("iPhone") > -1) {
                    // 모바일일때 iframe 화면 보이기
                    this.$refs.screen.style.display = "";
                    this.$refs.progress.style.display = "";
                } else {
                    // PC일때 iframe 가리고, 팝업
                    this.$refs.screen.style.display = "none";
                    this.$refs.progress.style.display = "none";
                }
                this.$refs.iframe.src = `/plugins/wizzpay/request?orderNo=${this.orderNo}&orderName=${encodeURI(this.orderName)}&paymentAmount=${this.paymentAmount}&buyerName=${this.buyerName}`;
            });
        },
    },
};
</script>

<style scoped>
#wizzpay .screen {
    position: fixed;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    z-index: 99999;
    overflow-y: scroll;
    background: rgba(0,0,0,0.5);
}
#wizzpay iframe {
    position: fixed;
    width: 100%;
    height: 100%;
}
.wizzpay .progress{
    position: fixed;
    width: 100%;
    height: 100%;
    left:0;
    top: 0;
    background: rgba(0,0,0,0.54);
}
.wizzpay .spinner {
    position: fixed;
    margin: auto;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    height: 28px;
    width: 28px;
    animation: rotate 0.8s infinite linear;
    border: 8px solid #fff;
    border-right-color: transparent;
    border-radius: 50%;
}
@keyframes rotate {
    0%    { transform: rotate(0deg); }
    100%  { transform: rotate(360deg); }
}
</style>
