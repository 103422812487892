var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('form', {
    ref: "form",
    attrs: {
      "name": "payment",
      "method": "post",
      "action": _vm.STARTURL
    }
  }, [_c('input', {
    attrs: {
      "type": "hidden",
      "name": "STARTPARAMS"
    },
    domProps: {
      "value": _vm.STARTPARAMS
    }
  })]);

}
var staticRenderFns = []

export { render, staticRenderFns }