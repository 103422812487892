<template>
    <form ref="form" name="payment" method="post">
        <input type="hidden" name="SERVICE_ID" v-model="serviceId">
        <input type="hidden" name="ORDER_ID" v-model="orderNo">
        <input type="hidden" name="ORDER_DATE" v-model="orderDate">
        <input type="hidden" name="AMOUNT" v-model="paymentAmount">
        <input type="hidden" name="ITEM_NAME" v-model="orderName">
        <input type="hidden" name="ITEM_CODE" v-model="orderCode">
        <input type="hidden" name="USER_ID" v-model="buyerUsername">
        <input type="hidden" name="USER_NAME" v-model="buyerName">
        <input type="hidden" name="USER_EMAIL" v-model="buyerEmail">
        <input type="hidden" name="MOBILE_NUMBER" v-model="buyerPhone">
        <input type="hidden" name="MOBILE_COMPANY_CODE" v-model="mobileCompanyCode">
        <input type="hidden" name="SOCIAL_NUMBER" v-model="authNumber">

        <input type="hidden" name="READONLY_HP" value="Y">
        <input type="hidden" name="INSTALLMENT_PERIOD" value="0:2:3:4:5:6:7:8:9:10:11:12"> <!-- 할부개월수 -->
        <input type="hidden" name="RETURN_URL" :value="returnUrl">
        <input type="hidden" name="CHECK_SUM" v-model="checkSum">

        <input type="hidden" name="CARD_TYPE" value="0000">
        <input type="hidden" name="APPNAME" value="WEB">
    </form>
</template>

<script>
import api from "@/api";
export default {
    props: {
        order: Object
    },
    data(){
        let {
            orderNo,
            orderName,
            buyer,
            receiver,
            paymentAmount,
            paymentMethod,
            isDeduction,
            telecom,
            authNumber,
            createdAt
        } = this.$props.order;

        return {
            // 결제정보
            serviceId: "",
            orderNo,
            orderName,
            orderCode: "GSHOP",
            orderDate: this.$dayjs(createdAt).format("YYYYMMDDHHmmss"),
            paymentAmount,
            paymentMethod,
            buyerUsername: receiver.username,
            buyerName: receiver.name,
            buyerPhone: receiver.phone,
            buyerEmail: receiver.email,

            telecom,
            authNumber,

            checkSum: null,

        };
    },
    mounted(){
        window.billgateComplete = (payload) => {
            this.$emit("complete", payload);
            this.$emit("change", payload);
            this.$emit("input", payload);
            this.show = false;
        };
    },
    methods: {
        async pay() {
            let { serviceId, creditGateway, creditGatewayMobile, mobileGateway } = await api.plugins.galaxia.billgate.config.get();
            let { checkSum } = await api.plugins.galaxia.billgate.generate({
                serviceId,
                orderNo: this.orderNo,
                paymentAmount: this.paymentAmount
            });

            this.serviceId = serviceId;
            this.checkSum = checkSum;

            // alert("serviceId = " + this.serviceId);
            // alert("orderNo = " + this.orderNo);
            // alert("orderDate = " + this.orderDate);
            // alert("paymentAmount = " + this.paymentAmount);
            // alert("orderName = " + this.orderName);
            // alert("orderCode = " + this.orderCode);
            // alert("buyerUsername = " + this.buyerUsername);
            // alert("buyerName = " + this.buyerName);
            // alert("buyerEmail = " + this.buyerEmail);
            // alert("buyerPhone = " + this.buyerPhone);
            // alert("checkSum = " + this.checkSum);
            
            switch(this.paymentMethod){
                case "creditcard": {
                    this.$refs.form.target = "payment";
                    this.$refs.form.action = creditGateway;

                    this.$nextTick( _ => {
                        var modalOption ="width=500,height=477,toolbar=no,location=no,status=no,menubar=no,scrollbars=no,resizable=no,left=150,top=150";
                        var modal = window.open("", "payment", modalOption);
        
                        if(modal == null){	//팝업 차단여부 확인
                            alert("팝업이 차단되어 있습니다.\n팝업차단을 해제하신 뒤 다시 시도하여 주십시오.");
                        }
        
                        //인코딩 euc-kr 처리
                        if (/edge/.test(window.navigator.userAgent.toLowerCase()) || /MSIE/.test(window.navigator.userAgent) || /Trident.*rv[ :]*11\./.test(window.navigator.userAgent) ) {
                            var befCharset = document.charset;
                            document.charset = "euc-kr";
                            // window.document.charset = "utf-8";
                            // document.charset = befCharset;
                        } else {
                            this.$refs.form.acceptCharset = "euc-kr";
                            // this.$refs.form.acceptCharset = "utf-8";
                        }

                        this.$refs.form.submit();
                        
                        if(befCharset) document.charset = befCharset;
                    });
                    break;
                }
                case "mobile": {
                    this.$refs.form.target = "payment";
                    this.$refs.form.action = mobileGateway;

                    this.$nextTick( _ => {
                        var modalOption ="width=500,height=740,toolbar=no,location=no,status=no,menubar=no,scrollbars=no,resizable=no,left=150,top=50";
                        var modal = window.open("", "payment", modalOption);
        
                        if(modal == null){	//팝업 차단여부 확인
                            alert("팝업이 차단되어 있습니다.\n팝업차단을 해제하신 뒤 다시 시도하여 주십시오.");
                        }
        
                        //인코딩 euc-kr 처리
                        if (/edge/.test(window.navigator.userAgent.toLowerCase()) || /MSIE/.test(window.navigator.userAgent) || /Trident.*rv[ :]*11\./.test(window.navigator.userAgent) ) {
                            var befCharset = document.charset;
                            document.charset = "euc-kr";
                            // window.document.charset = "utf-8";
                            // document.charset = befCharset;
                        } else {
                            this.$refs.form.acceptCharset = "euc-kr";
                            // this.$refs.form.acceptCharset = "utf-8";
                        }
                        
                        this.$refs.form.submit();
                        
                        if(befCharset) document.charset = befCharset;

                    });
    
                    break;
                }
            }

        },
    },
    computed: {
        returnUrl(){
            return window.location.origin + "/api/plugins/galaxia/billgate/response";
        },
        mobileCompanyCode(){
            switch(this.telecom){
                case "SKT": return "0000"; // SKT
                case "KTF": return "0001"; // KT
                case "LGT": return "0002"; // LG
                case "CJH": return "0011"; // 헬로모바일
                case "KCT": return "0010"; // KCT
                case "SKL": return "0012"; // SK7mobile
            }
        }
    }
}
</script>
